import type { ILegalTextParagraph } from "~/models/ILegalTextParagraph";
import { parseDate } from "~/utils/dates";

export function useFullParagraphTitle(
  paragraph?: MaybeRefOrGetter<ILegalTextParagraph>,
  short = false
) {
  const { t } = useI18n();
  const plainFn = (paragraph?: ILegalTextParagraph) => {
    if (!paragraph) {
      return "";
    }

    const { paragraph: name, legal_text, title, valid_until } = paragraph;

    let result = name;
    if (legal_text) {
      result += ` ${legal_text.name}`;
    }
    if (title && !short) {
      result += ` - ${title}`;
    }
    if (valid_until) {
      result += ` (${t("legal_text.old_version_short", {
        validUntil: parseDate(valid_until),
      })})`;
    }
    return result;
  };

  const title = computed(() => plainFn(toValue(paragraph)));

  return { title, plainFn };
}
