export function useCanonicalUrl() {
  const { active } = useFetchActiveEntry();

  const baseUrl = useBaseUrl();
  const pageContext = usePageContext();

  const path = computed(() => {
    if (
      active.value &&
      active.value.type === "schema" &&
      active.value.curated
    ) {
      return active.value.canonical_path + "/" + active.value.slug;
    }

    return pageContext.urlPathname;
  });

  const fullUrl = computed(() => {
    return baseUrl + path.value;
  });

  return { baseUrl, path, fullUrl };
}
