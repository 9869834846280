export const legal_text = {
  toc: "table of contents",
  old_version_short: "o.v. {validUntil}",
  middleColumn: {
    disclosure: "Disclosure",
    navigation: {
      add_tab: "Add tab",
      new_tab: "New tab",
      delete_tab: "Delete tab",
      scroll_tabs_to_left: "Scroll tabs to the left",
      scroll_tabs_to_right: "Scroll tabs to the right",
      next_paragraph: "Next paragraph",
      previous_paragraph: "Previous paragraph",
      close_old_version: "Close old version",
      old_version_long: "o.v. (old version)",
      old_version: "old version",
      old_version_available: "o.v. available",
      current_version: "current version",
      until: "until ",
      source: "Source: ",
      imported_from: "Imported from ",
      imported: "Imported:",
      type_of_reference: "Type of reference: ",
    },
    schema_editor: {
      download_schemata: "Download @:documents",
      download: "download",
      not_logged_in_tooltip: "Log in to download your @.lower:documents@:dot",
      can_not_download_curated_text:
        "Only self-created content can be downloaded collectively.",
    },
    newSchemata: {
      header: "Create new @.lower:documents or notes",
      text: "... by clicking on {clipboardIcon} or {paperIcon} in the toolbar above the legal text.",
    },
    existingSchemata: {
      header: "Show existing @:documents or notes",
      text: "... by clicking on {clipboardIcon} or {paperIcon} in the legal text on the overview column on the right.",
    },
    existingNotes: {
      header: "Notes on {paragraph}",
    },
  },
  reference: {
    create_reference: "Create reference",
    add_another_norm: "Add another norm",
    save: "Save",
    inputs: {
      norm: "Norm*",
    },
  },
  context: {
    edit: "Edit",
    delete_all: "Delete all",
    move: "Move",
  },

  textEditor: {
    status: {
      autoSave: "Autosave",
      active: "active",
      inactive: "inactive",
      last_edited: "Last edited:",
      hide: "Hide",
      abort: "Abort",
      publish: "Publish",
      confirm_unpublish: "Are you sure you want to unpublish?",
      confirm_publish: "Are you sure you want to publish?",
    },

    buttons: {
      schema: "@:document",
      note: "Note",
      link_chain: "Reference",
      underline: "Underline",
      marker: "Marker",
      bookmark: "Bookmark",
      remove_bookmark: "Remove bookmark",
      delete: "Delete",
      eraser: "Eraser",
      pdf_download: "PDF download",
    },
    fontsize: {
      title: "font size",
    },
    colors: {
      title: "Color",
      yellow: "Yellow",
      orange: "Orange",
      red: "Red",
      purple: "Purple",
      blue: "Blue",
      green: "Green",
      light_yellow: "Light Yellow",
      light_orange: "Light Orange",
      light_red: "Light Red",
      light_purple: "Light Purple",
      light_blue: "Light Blue",
      light_green: "Light Green",
    },
    blockFormats: {
      heading1: "Heading 1",
      standard: "Standard",
      heading2: "A. Heading 2",
      heading3: "I. Heading 3",
      heading4: "1. Heading 4",
      heading5: "a) Heading 5",
      heading6: "aa) Heading 6",
      heading7: "(1) Heading 7",
      heading8: "(a) Heading 8",
      heading9: "(aa) Heading 9",
      heading10: "i. Heading 10",
    },
    tooltips: {
      summary: "Summary",
      reminder: "Reminder",
      definition: "Definition",
      problem: "Problem",
      structure_note: "Structure note",
      schema: "Add document",
      note: "Add note",
      link_chain: "Reference to other provisions",
      underline: "Underline",
      delete: "Delete",
      marker: "Marker",
      bookmark: "Bookmark provision",
      eraser: "Eraser",
      select_color: "Select color",
      edit_schema: "Edit @:document",
      stop_editing: "Stop editing",
      pdf_download: "download PDF",
      only_self_created_can_be_deleted:
        "Only @:documents created by you can be deleted.",
    },
    errors: {
      color_could_not_be_changed: "Color could not be changed.",
      marking_could_not_be_moved: "Marking could not be moved.",
      marking_could_not_be_added: "Marking could not be added.",
      link_chain_could_not_be_added: "Reference could not be added.",
      link_chain_could_not_be_saved: "Reference could not be saved.",
      content_could_not_be_changed: "Content could not be changed.",
    },
    helper_texts: {
      move_schema: `Click on a text passage in the norm to move the @:document "{entry}".`,
      move_note: `Click on a text passage in the norm to move the note "{entry}".`,
      move_link_chain: `Click on a text passage in the norm to move the reference.`,
      schema: "Create a @:document by clicking on the legal text.",
      note: "Create note by clicking on the legal text.",
      link_chain: "Create reference by clicking on the legal text.",
      underline: "Underline the legal text by marking it.",
      mark: "Highlight the legal text by marking it.",
      eraser: "Erase annotations by clicking on them.",
      bookmarkAdded: "Bookmark added",
      bookmarkRemoved: "Bookmark removed",
    },
  },
  rightColumn: {
    schemas: {
      title: "@:documents",
      to: "for ",
      no_schemas_for_entry: "No @:documents available for the norm.",
      no_related_schemas: "No @:documents available",
      lexmea_schemas: "LexMea @:documents",
      own_schemas: "Own @:documents",
      not_logged_in_tooltip: "Log in to see your own @:documents.",
    },
    note_types: {
      knowledge: "Knowledge",
      definition: "Definition",
      note: "Note",
    },
    notes: {
      title: "Notes",
      to: "for ",
      add_new_note_by_clicking:
        "Create a note in the legal text by clicking on the note icon in the toolbar to display it here.",
    },
  },
};
