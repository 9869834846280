export const settings = {
  base_form: {
    title: "Titel",
    form: "Form",
  },
  languages: {
    german: "Deutsch",
    english: "Englisch",
  },
  toast: {
    link_successfully_sent: "Link erfolgreich gesendet!",
    maximum_file_size_profile_image:
      "Die maximale Dateigröße für Profilbilder beträgt 4 MB und die Bilddateien sind auf PNG, JPG oder GIF beschränkt.",
    error_uploading_profile_image: "Fehler beim Hochladen des Profilbildes.",
    error_deleting_profile_image: "Fehler beim Löschen des Profilbildes.",
    error_deleting_account: "Fehler beim Löschen des Kontos.",
  },
  hello: "Hallo",
  manage_lexmea_account: "LexMea-Konto verwalten",
  edit_account: "Konto verwalten",
  edit_profile: "Profil bearbeiten",
  edit_password: "Passwort ändern",
  data_privacy_cockpit: "Datenschutz-Cockpit",
  save_changes: "Änderungen speichern",
  change: "Ändern",
  abort: "Abbrechen",
  continue: "Weiter",
  email: {
    change_email: "E-mail-Adresse ändern",
    current_email: "Deine derzeitige E-Mail-Adresse lautet:",
    current_email_alt: "Du verwendest derzeit die E-Mail-Adresse:",
    new_email: "Welche E-Mail-Adresse möchtest du künftig verwenden?",
    labels: {
      new_email: "Neue E-Mail-Adresse",
      confirm_email: "Neue E-Mail-Adresse wiederholen",
    },
  },
  username: {
    change_username: "Benutzername ändern",
    current_username: "Du verwendest derzeit den Benutzernamen:",
    new_username: "Welchen Benutzernamen möchtest du künftig verwenden?",
    labels: {
      new_username: "Neuer Benutzername",
    },
  },
  profile: {
    title: "Profil",
    how_can_we_help:
      "Womit soll LexMea dir helfen? Wir geben diese Informationen nicht an Dritte weiter!",
  },
  profileImage: {
    change_profile_image_in_settings:
      "Ändere dein Profilbild / deinen Nutzernamen in den Profileinstellungen.",
    delete_profileImage: "Willst du dein Profilbild entfernen?",
    delete_profile_image_button: "Profilbild entfernen",
    delete: "Entfernen",
  },
  account: {
    delete_account: "Konto löschen",
    click_here_to_delete_account:
      "Klicke hier, um dein Konto mit allen Daten dauerhaft zu löschen.",
    explainer_message:
      "Wenn du dein Konto löschst, löschen wir alle von dir vorgenommenen Personalisierungen deines Online-Gesetzbuchs, wie etwa deine Markierungen, Verweise, Schemata etc. unwiderruflich. Diese Informationen können wir anschließend nicht wiederherstellen.",
    confirm_password: "Gib zur Bestätigung dein Passwort ein:",
    last_warning:
      "Du bist dabei, dein Konto dauerhaft zu löschen. Wenn du es wirklich löschen möchtest, klicke auf „Konto löschen“. Sobald du eine Anfrage zum Löschen deines Kontos eingereicht hast, schicken wir dir eine Mail mit der Bestätigung des Löschvorgangs. Damit kannst du dein Konto innerhalb von 30 Tagen reaktivieren und den Löschvorgang abbrechen. Nach 30 Tagen beginnt der Löschvorgang und du kannst deine hinzugefügten Inhalte oder Informationen dann nicht mehr zurückerlangen.",
    last_warning_2:
      "Lediglich soweit dies zur Erhaltung von Beweismitteln bezüglich der Bereitstellung etwaiger kostenpflichtiger Dienste unseres Angebotes erforderlich ist speichern wir für die Dauer der gesetzlichen Verjährungsfristen die Metadaten (jedoch keine Inhalte).",
    deleted: "Dein Konto wurde gelöscht.",
    email_changed: "Deine E-Mail Adresse wurde geändert.",
  },
  privacy: {
    email_notifications: "E-Mail-Benachrichtigungen",
    security_relevant_mails: "Sicherheitsrelevante Mails",
    security_relevant_mails_info:
      "Sicherheitsrelevante Mails senden wir dir nur anlassbezogen und nur soweit dies zur Gewährleistung der Integrität deines Accounts oder unserer Seite zwingend erforderlich ist.",
    newsletter: "Newsletter",
    newsletter_info:
      "Spam-Mails nerven. Wir schicken dir daher nur bei wirklich relevanten Neuerungen und maximal zweimal pro Monat eine Mail – etwa bei der Einführung gänzlich neuer Funktionen auf LexMea, besonderen Events in deiner Region oder zu deinem Rechtsgebiet.",
    never: "Nie",
    subscribe_newsletter: "Newsletter abonnieren",
    relevant_news: "Relevante Neuigkeiten",
    data_privacy_rights: "Datenschutzrechte",
    data_privacy_rights_info:
      "Datenschutz hat für uns höchste Priorität. Wir gehen daher an zahlreichen Stellen über das nach der europäischen Datenschutzgrundverordnung erforderliche Schutzniveau hinaus. Mehr dazu erfährst du in unseren {data_privacy_rights_link}.",
    data_privacy_rights_link: "Datenschutzhinweisen",
    data_privacy_text: {
      intro:
        "Gemäß der Datenschutzgrundverordnung (DSGVO) hat jede Person das Recht auf:",
      consent_revocation: "Widerruf der Einwilligung",
      access_personal_data:
        "Auskunft bzgl. der eigenen personenbezogenen Daten",
      rectification: "Berichtigung",
      erasure: "Löschung",
      restriction_of_processing: "Einschränkung der Verarbeitung",
      data_portability: "Datenübertragbarkeit",
      objection: "Widerspruch",
      complaint_to_supervisory_authority:
        "Beschwerde bei einer Aufsichtsbehörde",
      contact_us_with_your_rights:
        "Sende uns zur Geltendmachung einer dieser Rechte einfach eine formlose {message} und wir kümmern uns persönlich um dein Anliegen.",
      message: "Nachricht",
      last_paragraph_1:
        "Jede betroffene Person hat unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde.",
      last_paragraph_2:
        "Die für LexMea zuständige Aufsichtsbehörde ist die Berliner Beauftragte für Datenschutz und Informationsfreiheit,",
    },
  },
  password: {
    forgot_password: "Passwort vergessen?",
    forgot_password_info_text:
      "Du hast dein Passwort vergessen? Klicke auf „Passwort zurücksetzen“ und wir senden dir einen Link zum Zurücksetzen deines Passworts an deine hinterlegte E-Mail-Adresse.",
    reset_password: "Password zurücksetzen",
    change_password: "Passwort ändern",
    redirect: "Du wirst in 5 Sekunden zum Bücherregal weitergeleitet.",
    send_link: "Link senden",
    regex:
      "Verwende 9 oder mehr Zeichen mit einer Mischung aus Buchstaben, Ziffern und Symbolen",
    confirmation:
      "Wir senden dir eine Mail zum Zurücksetzen deines Passworts an: {email} Du hast 60 Minuten Zeit, um durch Klicken auf den darin enthaltenen Link ein neues Passwort zu vergeben. Siehe auch in deinem Spam-Postfach nach.",
    labels: {
      old_password: "Altes Passwort",
      new_password: "Neues Passwort",
      new_password_repeat: "Neues Passwort (wiederholen)",
      password: "Passwort",
    },
  },
};
