import type { IUserEntry } from "~/models/IUserEntry";
import type { Props } from "~/pages/legaltext/props";

export function useActiveEntryRouteParams() {
  const routeParams = useRouteParams<Props>();

  if (!routeParams) {
    throw new Error("useActiveEntry must be used in a route context");
  }

  const slug = computed(() => routeParams.activeEntrySlug);
  const id = computed(() =>
    routeParams.activeEntryId ? parseInt(routeParams.activeEntryId) : undefined
  );
  const type = computed(() => routeParams.activeEntryType);

  // TODO: why not compare to active?
  const isActive = (entry: IUserEntry) => {
    const sameId = entry.id === id.value;
    const sameSlug = Boolean(
      "slug" in entry && entry.slug && entry.slug === slug.value
    );
    const sameType = entry.type === type.value;
    return (sameType && sameId) || sameSlug;
  };

  return { slug, id, type, isActive };
}
