export const ui = {
  new_tab: "Open in new tab",
  expand_box: "Expand box",
  collapse_box: "Collapse box",
  close: "Close",
  save: "Save",
  filter: "Filter",
  actions: "Actions",
  rotate: "Rotate",
  collapse: "Collapse",
  expand: "Expand",
  expand_all: "Expand all",
  toast: {
    error: {
      error_while_syncing:
        "Error while syncing. It will be tried again on the next page load.",
    },
  },
  new_version_handler: {
    new_version_available: "A new version is available.",
    reload: "Reload",
    abort: "Abort",
  },
  search: {
    title: "Search",
    feedback: "Feedback",
    laws_title: "Laws",
    schemata_title: "@:documents & notes",
    no_results: "No results found",
    no_laws: "No laws found for the search query",
    no_schemata_notes:
      "No @.lower:documents / notes found for the search query",
    missing_something: "Missing something? Let us know via {feedback}!",
  },
};
