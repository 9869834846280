import type { CanBeActive } from "~/models/IUserEntry";
import type { UseQueryOptions } from "@tanstack/vue-query";

export function useFetchActiveEntry() {
  const localEntries = useLocalEntries();
  const queryClient = useQueryClient();
  const { slug, id, type, isActive } = useActiveEntryRouteParams();
  const { entries } = queries();

  const activeLocalEntry = useArrayFind(localEntries, ({ data }) =>
    isActive(data)
  );

  const paramsPresent = logicOr(id, type);

  const enabled = logicAnd(paramsPresent, logicNot(activeLocalEntry));

  const queryOptions = computed(() => {
    if (!enabled.value) {
      return {
        enabled,
      };
    }
    const typeContext = entries.byType(
      type.value as unknown as CanBeActive["type"]
    )._ctx;
    const query = slug.value
      ? typeContext.bySlug(slug.value)
      : typeContext.byId(id.value!);

    return {
      enabled,
      queryKey: query.queryKey,
      queryFn: (ctx) => query.queryFn(ctx) as CanBeActive,
    };
  });

  const query = useQuery(queryOptions as UseQueryOptions<CanBeActive>);

  const active = computed(() => {
    if (!paramsPresent.value) {
      return;
    }

    if (query.data.value) {
      return query.data.value;
    }

    if (!activeLocalEntry.value) {
      return;
    }

    return activeLocalEntry.value.data as CanBeActive;
  });

  whenever(
    () => {
      const data = query.data.value;
      if (!data || data.type !== "schema" || !data.curated || !data.slug) {
        return;
      }

      return data;
    },
    (schema) => {
      const ctx = entries.byType("schema")._ctx;
      const { queryKey } = slug.value
        ? ctx.byId(schema.id)
        : ctx.bySlug(schema.slug!);
      queryClient.setQueryData(queryKey, schema);
    }
  );

  return {
    enabled,
    query,
    active,
  };
}
